import {
	FragmentAccountNavigationLinksType,
	FragmentAccountPreferencesType,
	FragmentAppNudgeType,
	FragmentAppReviewType,
	FragmentAuthMobileIntroScreenType,
	FragmentCancelationFlowType,
	FragmentCelebrateCheckoutType,
	FragmentChallengesType,
	FragmentCheckoutFooterType,
	FragmentCheckoutOneTimeOfferType,
	FragmentCheckoutOptionsType,
	FragmentCheckoutPaymentType,
	FragmentCheckoutSubscriptionPlansType,
	FragmentCoinEducationType,
	FragmentCoinType,
	FragmentCommunityCreateTopicType,
	FragmentCommunityExpertQuotesType,
	FragmentCommunityHomeType,
	FragmentCommunityTopicType,
	FragmentConsentType,
	FragmentCookieConsentType,
	FragmentCurrentSubscriptionType,
	FragmentEarnedCoinsType,
	FragmentExploreType,
	FragmentFlowsType,
	FragmentFreeUserFirstCourseCompletedType,
	FragmentGoalCardType,
	FragmentGroupSessionsType,
	FragmentHomeTasksType,
	FragmentInAppPurchasesType,
	FragmentItoChatType,
	FragmentItoPlanConfigType,
	FragmentKickstartChallengeType,
	FragmentMagicAuthEmailSentType,
	FragmentMagicAuthRecoveryType,
	FragmentMagicAuthType,
	FragmentMagicLinkApprovalType,
	FragmentMagicLinkRequesterType,
	FragmentMemoriesType,
	FragmentOneActivityADayType,
	FragmentOptInNotificationsCardType,
	FragmentPhaseCardPaymentFailedSafeType,
	FragmentPhaseCardPaymentFailedType,
	FragmentPhaseCardPaywalledType,
	FragmentProgramType,
	FragmentRedirectToAppType,
	FragmentReferralPartnerType,
	FragmentReferType,
	FragmentRegisterPrefaceExitModalType,
	FragmentRoutineType,
	FragmentSQScorePillarsType,
	FragmentStartTrialCardType,
	FragmentStreaksRecoveryType,
	FragmentStreaksType,
	FragmentUnlockActivityType,
	FragmentUserDeletionType,
} from '@wearemojo/sanity-schema';

// 👋 Hey, you there making the new fragment - check your fragment's fields!
//
// Use BlockContentWrapper/InlineContentWrapper, rather than
// BlockContent/InlineContent. This will help keep our Sanity attribute usage in
// check.
//
// See: https://wearemojo.slack.com/archives/C01BPGCMD7D/p1719830929147649?thread_ts=1712617108.610099&cid=C01BPGCMD7D

export enum FragmentType {
	AccountNavigationLinks = 'FragmentAccountNavigationLinks',
	AccountPreferences = 'FragmentAccountPreferences',
	AppNudge = 'FragmentAppNudge',
	AppReview = 'FragmentAppReview',
	AuthMobileIntroScreen = 'FragmentAuthMobileIntroScreen',
	CancelationFlow = 'FragmentCancelationFlow',
	CelebrateCheckout = 'FragmentCelebrateCheckout',
	Challenges = 'FragmentChallenges',
	CheckoutFooter = 'FragmentCheckoutFooter',
	CheckoutOneTimeOffer = 'FragmentCheckoutOneTimeOffer',
	CheckoutOptions = 'FragmentCheckoutOptions',
	CheckoutPayment = 'FragmentCheckoutPayment',
	CheckoutSubscriptionPlans = 'FragmentCheckoutSubscriptionPlans',
	CoinEducation = 'FragmentCoinEducation',
	CommunityCreateTopic = 'FragmentCommunityCreateTopic',
	CommunityExpertQuotes = 'FragmentCommunityExpertQuotes',
	CommunityHome = 'FragmentCommunityHome',
	CommunityTopic = 'FragmentCommunityTopic',
	Consent = 'FragmentConsent',
	CookieConsent = 'FragmentCookieConsent',
	CurrentSubscription = 'FragmentCurrentSubscription',
	Explore = 'FragmentExplore',
	EarnedCoins = 'FragmentEarnedCoins',
	Flows = 'FragmentFlows',
	FragmentCoin = 'FragmentCoin',
	FreeUserFirstCourseCompleted = 'FragmentFreeUserFirstCourseCompleted',
	GoalCard = 'FragmentGoalCard',
	GroupSessions = 'FragmentGroupSessions',
	HomeTasks = 'FragmentHomeTasks',
	InAppPurchases = 'FragmentInAppPurchases',
	ItoChat = 'FragmentItoChat',
	ItoPlanConfig = 'FragmentItoPlanConfig',
	KickstartChallenge = 'FragmentKickstartChallenge',
	MagicAuth = 'FragmentMagicAuth',
	MagicAuthEmailSent = 'FragmentMagicAuthEmailSent',
	MagicAuthRecovery = 'FragmentMagicAuthRecovery',
	MagicLinkApproval = 'FragmentMagicLinkApproval',
	MagicLinkRequester = 'FragmentMagicLinkRequester',
	Memories = 'FragmentMemories',
	OneActivityADay = 'FragmentOneActivityADay',
	OptInNotificationsCard = 'FragmentOptInNotificationsCard',
	PhaseCardPaymentFailed = 'FragmentPhaseCardPaymentFailed',
	PhaseCardPaymentFailedSafe = 'FragmentPhaseCardPaymentFailedSafe',
	PhaseCardPaywalled = 'FragmentPhaseCardPaywalled',
	Program = 'FragmentProgram',
	RedirectToApp = 'FragmentRedirectToApp',
	Refer = 'FragmentRefer',
	ReferralPartner = 'FragmentReferralPartner',
	RegisterPrefaceExitModal = 'FragmentRegisterPrefaceExitModal',
	Routine = 'FragmentRoutine',
	SQScorePillars = 'FragmentSQScorePillars',
	StartTrialCard = 'FragmentStartTrialCard',
	Streaks = 'FragmentStreaks',
	StreaksRecovery = 'FragmentStreaksRecovery',
	UnlockActivity = 'FragmentUnlockActivity',
	UserDeletion = 'FragmentUserDeletion',
}

export type FragmentTypes = {
	[FragmentType.AccountNavigationLinks]: FragmentAccountNavigationLinksType;
	[FragmentType.AccountPreferences]: FragmentAccountPreferencesType;
	[FragmentType.AppNudge]: FragmentAppNudgeType;
	[FragmentType.AppReview]: FragmentAppReviewType;
	[FragmentType.AuthMobileIntroScreen]: FragmentAuthMobileIntroScreenType;
	[FragmentType.CancelationFlow]: FragmentCancelationFlowType;
	[FragmentType.CelebrateCheckout]: FragmentCelebrateCheckoutType;
	[FragmentType.Challenges]: FragmentChallengesType;
	[FragmentType.CheckoutFooter]: FragmentCheckoutFooterType;
	[FragmentType.CheckoutOneTimeOffer]: FragmentCheckoutOneTimeOfferType;
	[FragmentType.CheckoutOptions]: FragmentCheckoutOptionsType;
	[FragmentType.CheckoutPayment]: FragmentCheckoutPaymentType;
	[FragmentType.CheckoutSubscriptionPlans]: FragmentCheckoutSubscriptionPlansType;
	[FragmentType.CoinEducation]: FragmentCoinEducationType;
	[FragmentType.CommunityCreateTopic]: FragmentCommunityCreateTopicType;
	[FragmentType.CommunityExpertQuotes]: FragmentCommunityExpertQuotesType;
	[FragmentType.CommunityHome]: FragmentCommunityHomeType;
	[FragmentType.CommunityTopic]: FragmentCommunityTopicType;
	[FragmentType.Consent]: FragmentConsentType;
	[FragmentType.CookieConsent]: FragmentCookieConsentType;
	[FragmentType.CurrentSubscription]: FragmentCurrentSubscriptionType;
	[FragmentType.Explore]: FragmentExploreType;
	[FragmentType.EarnedCoins]: FragmentEarnedCoinsType;
	[FragmentType.Flows]: FragmentFlowsType;
	[FragmentType.FragmentCoin]: FragmentCoinType;
	[FragmentType.FreeUserFirstCourseCompleted]: FragmentFreeUserFirstCourseCompletedType;
	[FragmentType.GoalCard]: FragmentGoalCardType;
	[FragmentType.GroupSessions]: FragmentGroupSessionsType;
	[FragmentType.HomeTasks]: FragmentHomeTasksType;
	[FragmentType.InAppPurchases]: FragmentInAppPurchasesType;
	[FragmentType.ItoChat]: FragmentItoChatType;
	[FragmentType.ItoPlanConfig]: FragmentItoPlanConfigType;
	[FragmentType.KickstartChallenge]: FragmentKickstartChallengeType;
	[FragmentType.MagicAuth]: FragmentMagicAuthType;
	[FragmentType.MagicAuthEmailSent]: FragmentMagicAuthEmailSentType;
	[FragmentType.MagicAuthRecovery]: FragmentMagicAuthRecoveryType;
	[FragmentType.MagicLinkApproval]: FragmentMagicLinkApprovalType;
	[FragmentType.MagicLinkRequester]: FragmentMagicLinkRequesterType;
	[FragmentType.Memories]: FragmentMemoriesType;
	[FragmentType.OneActivityADay]: FragmentOneActivityADayType;
	[FragmentType.OptInNotificationsCard]: FragmentOptInNotificationsCardType;
	[FragmentType.PhaseCardPaymentFailed]: FragmentPhaseCardPaymentFailedType;
	[FragmentType.PhaseCardPaymentFailedSafe]: FragmentPhaseCardPaymentFailedSafeType;
	[FragmentType.PhaseCardPaywalled]: FragmentPhaseCardPaywalledType;
	[FragmentType.Program]: FragmentProgramType;
	[FragmentType.RedirectToApp]: FragmentRedirectToAppType;
	[FragmentType.Refer]: FragmentReferType;
	[FragmentType.ReferralPartner]: FragmentReferralPartnerType;
	[FragmentType.RegisterPrefaceExitModal]: FragmentRegisterPrefaceExitModalType;
	[FragmentType.Routine]: FragmentRoutineType;
	[FragmentType.SQScorePillars]: FragmentSQScorePillarsType;
	[FragmentType.StartTrialCard]: FragmentStartTrialCardType;
	[FragmentType.Streaks]: FragmentStreaksType;
	[FragmentType.StreaksRecovery]: FragmentStreaksRecoveryType;
	[FragmentType.UnlockActivity]: FragmentUnlockActivityType;
	[FragmentType.UserDeletion]: FragmentUserDeletionType;
};
