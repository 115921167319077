import Cher from '@wearemojo/cher';
import { BackendError } from '@wearemojo/json-client';

import { requestUrlSanitizer } from '../utils/requests';

type strippedCher = Pick<Cher, 'code'> & {
	reasons?: strippedCher[];
	meta_error_name?: string;
	meta_error_message?: string;
	meta_error_detail?: unknown;
	request_url?: string;
};

export const stripCher = (error: Cher): strippedCher => {
	const requestUrl =
		error instanceof BackendError && error.requestUrl != null
			? requestUrlSanitizer(error.requestUrl)
			: undefined;
	const metaError = error.meta?.error as
		| (Error & Record<string, unknown>)
		| undefined;
	return {
		code: error.code,
		reasons: error.reasons?.map(stripCher),
		meta_error_name: metaError?.name,
		meta_error_message: metaError?.message,
		meta_error_detail: metaError?.detail,
		request_url: requestUrl,
	};
};
