import { useMemo } from 'react';

import { useClientContext } from '../providers/ClientContextProvider';
import { deterministicShuffle } from '../utils/shuffle';

const styles = ['cloudflare' as const, 'gcr_old' as const, 'gcr_new' as const];

const useBackendRoutingStyle = () => {
	const clientContext = useClientContext();

	return useMemo(() => {
		if (!clientContext) return;
		const seed = `${clientContext.device.vendorId}/v1`;
		return deterministicShuffle(styles, seed)[0]!;
	}, [clientContext]);
};

export default useBackendRoutingStyle;
