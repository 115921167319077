import { ProgramActivityType } from '@wearemojo/sanity-schema';
import {
	stringToSQScorePillar,
	useSanityImageAttributes,
} from '@wearemojo/ui-components';
import { useCallback } from 'react';

import { FragmentType } from '../content/FragmentType';
import { useFragment } from './content/useFragment';

const useActivityPillar = () => {
	const { scorePillars } = useFragment(FragmentType.SQScorePillars);
	const imageAttr = useSanityImageAttributes(480);

	return useCallback(
		(activity: ProgramActivityType) => {
			const pillar = activity.metadata?.find((m) => m.key === 'pillar')?.value;
			const sqPillar = pillar ? stringToSQScorePillar(pillar) : undefined;

			if (!sqPillar) return;

			return {
				...scorePillars[sqPillar],
				pillar: sqPillar,
				pillarImageSource: imageAttr(scorePillars[sqPillar].pillarImage).uri,
				rewardImageSource: imageAttr(scorePillars[sqPillar].rewardImage).uri,
			};
		},
		[imageAttr, scorePillars],
	);
};

export default useActivityPillar;
