import { useEffect } from 'react';

import { useTrackExperiment } from '../hooks/analytics/useTrackExperiment';
import useBackendRoutingStyle from '../hooks/useBackendRoutingStyle';
import { logger } from '../utils/logging';

const useTrackBackendRoutingStyle = () => {
	const trackExperiment = useTrackExperiment();
	const routingStyle = useBackendRoutingStyle();

	useEffect(() => {
		if (!routingStyle) return;

		trackExperiment({
			experiment_key: 'backend_routing_style',
			result_key: routingStyle,
		});

		logger.logInfo('Backend routing style tracked', { routingStyle });
	}, [routingStyle, trackExperiment]);
};

export default useTrackBackendRoutingStyle;
