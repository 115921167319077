import { useSignupAttribution } from './analytics/attribution';
import useTrackBackendRoutingStyle from './analytics/backendRoutingStyle';
import { useTrackPerformance } from './analytics/performance';
import useTrackScreenshot from './analytics/screenshot';

const AnalyticsEffects = () => {
	useSignupAttribution();
	useTrackScreenshot();
	useTrackPerformance();
	useTrackBackendRoutingStyle();

	return null;
};

export default AnalyticsEffects;
