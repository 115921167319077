import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { BackendSystem } from '@wearemojo/api-client';
import Cher from '@wearemojo/cher';
import {
	Stack,
	StaffKnob,
	Text,
	TextInput,
	useUIContext,
} from '@wearemojo/ui-components';
import { Spacing } from '@wearemojo/ui-constants';
import { format } from 'date-fns';
import { useCallback, useContext, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Linking } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import {
	defaultSanityConfig,
	sandboxSanityConfig,
	useBackendEnvs,
} from '../../environment';
import { useApiClient } from '../api';
import { useWhoopsHandler } from '../errors/WhoopsProvider';
import useGetRevenuecatConfig from '../hooks/iap/useGetRevenuecatConfig';
import useEndpointMutation from '../hooks/queries/useEndpointMutation';
import useAppDispatch from '../hooks/useAppDispatch';
import useAppReload from '../hooks/useAppReload';
import { useAppSelector } from '../hooks/useAppSelector';
import useBackendConfig from '../hooks/useBackendConfig';
import useItoDevModeEnabled from '../hooks/useItoDevModeEnabled';
import useItoFeatureFlag from '../hooks/useItoFeatureFlag';
import { useLocalization } from '../hooks/useLocalization';
import useOnLogout from '../hooks/useOnLogout';
import usePseudonym from '../hooks/usePseudonym';
import useResetNavigation from '../hooks/useResetNavigation';
import { ScreenLinkProvider } from '../navigation/links';
import { RootParamList } from '../navigation/params';
import ScreenKey from '../navigation/ScreenKey';
import { useNotificationPermission } from '../notifications';
import { useClientContext } from '../providers/ClientContextProvider';
import { useSubscriptionSituation } from '../providers/SubscriptionStateProvider';
import { SecureStorage } from '../storage';
import { SecureStoreLoggedInState } from '../storage/loginState';
import api from '../store/api';
import ApiTag from '../store/api/ApiTag';
import useCurrentTrackingDate from '../store/api/hooks/useCurrentTrackingDate';
import {
	selectEnabledLogs,
	selectSanityConfig,
	setBackendConfigOverride,
	setSanityConfig,
	toggleItoDevMode,
	toggleLogsEnabled,
} from '../store/dev';
import { resetOnceData } from '../store/once';
import {
	selectImpersonatedUserId,
	selectOriginalUserId,
	selectSessionId,
	setImpersonatedUserId,
} from '../store/session';
import { logger, LogType } from '../utils/logging';
import { AppInstanceContext } from './AppInstance';

export default () => {
	const { styles } = useStyles(stylesheet);
	const [isExpanded, setIsExpanded] = useState(false);
	const dispatch = useAppDispatch();
	const resetNavigation = useResetNavigation();
	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();
	const backendConfig = useBackendConfig();
	const backendEnvs = useBackendEnvs();
	const { isNative, isIos } = useUIContext();
	const clientContext = useClientContext();
	const impersonatedUserId = useAppSelector(selectImpersonatedUserId);
	const apiClient = useApiClient();
	const whoops = useWhoopsHandler();
	const userId = useAppSelector(selectOriginalUserId);
	const sessionId = useAppSelector(selectSessionId);
	const rcUserId = useGetRevenuecatConfig()?.data?.appUserId;
	const logsEnabled = useAppSelector(selectEnabledLogs);
	const { itoDevModeEnabled } = useItoDevModeEnabled();
	const { isItoOn } = useItoFeatureFlag();
	const sanityConfig = useAppSelector(selectSanityConfig);
	const { hasSubscription } = useSubscriptionSituation();
	const pseudonymId = usePseudonym().data?.pseudonymId;
	const sanityDataset = sanityConfig?.dataset || 'production';

	const [notificationPermission, notificationRequest] =
		useNotificationPermission();
	const onPressReload = useAppReload();
	const { key: appInstanceKey } = useContext(AppInstanceContext);
	const notificationStatus = notificationPermission?.granted;

	const toggleLogs = useCallback(
		(logType: LogType) => {
			dispatch(toggleLogsEnabled(logType));
			logger.setEnabled(logType, !logsEnabled[logType]);
		},
		[dispatch, logsEnabled],
	);

	const toggleItoDebugMode = useCallback(() => {
		dispatch(toggleItoDevMode());
	}, [dispatch]);

	const setBackendConfigEnv = useCallback(
		(backendEnv: (typeof backendEnvs)[number]) => {
			dispatch(
				setBackendConfigOverride({
					system: backendEnv.system,
					env: backendEnv.name,
					tag: backendConfig.tag,
				}),
			);
		},
		[backendConfig.tag, dispatch],
	);

	const setBackendConfigTag = useCallback(
		(tag?: string) =>
			dispatch(
				setBackendConfigOverride({
					system: backendConfig.system,
					env: backendConfig.env,
					tag,
				}),
			),
		[backendConfig.env, backendConfig.system, dispatch],
	);

	const [createTestUser] = useEndpointMutation(
		api.endpoints.createTestUser.useMutation(),
	);
	const onCreateTestUser = useCallback(async () => {
		if (!sessionId) return;
		const res = await createTestUser({ sessionId });
		if (res.data) {
			try {
				await apiClient.attachUserToSession(res.data.authzToken);
				await SecureStoreLoggedInState.setIsLoggedIn();
				resetNavigation({ name: ScreenKey.Welcome });
			} catch (error) {
				whoops(Cher.parseOrWrap(error, 'user_attach_failed'));
			}
		}
	}, [createTestUser, sessionId, apiClient, whoops, resetNavigation]);

	const [createTestSubscription] = useEndpointMutation(
		api.endpoints.createTestSubscription.useMutation(),
	);
	const onCreateTestSubscription = useMemo(() => {
		if (!userId) return;
		return () => createTestSubscription({ userId });
	}, [createTestSubscription, userId]);

	const closeOptions = useMemo(
		() => ({
			onPressEffect: () => setIsExpanded(false),
		}),
		[],
	);

	const { timezone } = useLocalization();

	const [resetUserActivity] = useEndpointMutation(
		api.endpoints.resetAllProgress.useMutation(),
	);

	const trackingDate = useCurrentTrackingDate();
	const [testIncrementTrackingDate] = useEndpointMutation(
		api.endpoints.testIncrementTrackingDate.useMutation(),
	);

	const [testCompleteActivityDays] = useEndpointMutation(
		api.endpoints.testCompleteActivityDays.useMutation(),
	);

	const { onLogout } = useOnLogout();

	const changeSanityProject = useCallback(() => {
		const isSandbox = sanityConfig?.dataset === sandboxSanityConfig?.dataset;
		const newConfig = isSandbox ? defaultSanityConfig : sandboxSanityConfig;
		dispatch(setSanityConfig(newConfig));
		setTimeout(() => {
			dispatch(api.util.invalidateTags([ApiTag.Sanity]));
		}, 300);
	}, [dispatch, sanityConfig]);

	const controls = useMemo(() => {
		return [
			[
				<StaffKnob.Control
					key="retool-user"
					title="Retool (user) →"
					icon="🔧"
					onPress={() => {
						const environment =
							backendConfig.system === 'prod' ? 'production' : 'staging';
						const url = `https://wearemojo.retool.com/apps/d8260410-1a4d-11ed-9239-6fcb50dbccbb/ops/user?_environment=${environment}#tab=learn_tracking&user_id=${userId}`;
						Linking.openURL(url);
					}}
				/>,
				onPressReload && (
					<StaffKnob.Control
						key="reload-app-instance"
						title={`Reload app instance (key: ${appInstanceKey})`}
						icon="🔁"
						onPress={onPressReload}
					/>
				),
				<StaffKnob.Control
					key="clear-once-data"
					title="Clear once data"
					onPress={() => dispatch(resetOnceData())}
					icon="1️⃣"
				/>,
				<StaffKnob.Control
					key="purge-storage"
					title="Purge storage"
					onPress={async () => {
						await SecureStorage.reinitialize();
						onPressReload();
					}}
					icon="🗑"
				/>,
				userId && (
					<StaffKnob.Control
						key="reset-activity-progress"
						title="Reset activity progress"
						onPress={() => resetUserActivity({ userId })}
						icon="🧹"
					/>
				),
				userId && (
					<StaffKnob.Control
						key="test-increment-tracking-date"
						title="Test increment tracking date"
						onPress={() => testIncrementTrackingDate({ userId, timezone })}
						icon="📅"
					/>
				),
				userId && (
					<StaffKnob.Control
						key="test-complete-1-day"
						title="Test complete 1 day"
						onPress={() =>
							testCompleteActivityDays({ userId, timezone, days: 1 })
						}
						icon="1️⃣"
					/>
				),
				userId && (
					<StaffKnob.Control
						key="test-complete-3-days"
						title="Test complete 3 days"
						onPress={() =>
							testCompleteActivityDays({ userId, timezone, days: 3 })
						}
						icon="3️⃣"
					/>
				),
				userId && (
					<StaffKnob.Control
						key="test-complete-7-days"
						title="Test complete 7 days"
						onPress={() =>
							testCompleteActivityDays({ userId, timezone, days: 7 })
						}
						icon="7️⃣"
					/>
				),
				userId && (
					<StaffKnob.Control
						key="test-complete-30-days"
						title="Test complete 30 days"
						onPress={() =>
							testCompleteActivityDays({ userId, timezone, days: 30 })
						}
						icon="💣"
					/>
				),

				<StaffKnob.Control
					key="request-notifications"
					title="Request notifications"
					onPress={() => notificationRequest()}
					icon={
						notificationStatus === undefined
							? '❓'
							: notificationStatus
								? '✅'
								: '❌'
					}
				/>,
				<StaffKnob.Control
					key="change-sanity-project"
					title={
						sanityDataset === 'production'
							? 'Change to sandbox sanity'
							: 'Change to production sanity'
					}
					onPress={() => changeSanityProject()}
					icon="📄"
				/>,
				<ScreenLinkProvider
					key="test-trigger-whoops"
					screen={ScreenKey.DevWhoops}
					options={closeOptions}
				>
					<StaffKnob.Control title="Whoops sandbox" icon="⚠️" />
				</ScreenLinkProvider>,
				backendConfig.system !== BackendSystem.prod && !userId && (
					<StaffKnob.Control
						key="create-test-user"
						title="Create a test user"
						onPress={onCreateTestUser}
						icon={'👤'}
					/>
				),
				backendConfig.system !== BackendSystem.prod &&
					userId &&
					!hasSubscription && (
						<StaffKnob.Control
							key="create-test-subscription"
							title="Create a test subscription"
							onPress={onCreateTestSubscription}
							icon={'🧾'}
						/>
					),
				<StaffKnob.Control
					key="test-js-crash"
					title="Test JS crash"
					onPress={() => {
						try {
							const a = {};
							// @ts-ignore
							a.b.c();
						} catch (error) {
							logger.captureException(error);
							throw error;
						}
					}}
					icon={'💥'}
				/>,
				<StaffKnob.Control
					key="analytics-logs-toggle"
					title={
						logsEnabled.analytics
							? 'Disable analytics logs'
							: 'Enable analytics logs'
					}
					onPress={() => toggleLogs('analytics')}
					icon={'🕵️'}
				/>,
				<StaffKnob.Control
					key="warning-logs-toggle"
					title={
						logsEnabled.warning ? 'Disable warning logs' : 'Enable warning logs'
					}
					onPress={() => toggleLogs('warning')}
					icon={'⚠️'}
				/>,
				<StaffKnob.Control
					key="error-logs-toggle"
					title={logsEnabled.error ? 'Disable error logs' : 'Enable error logs'}
					onPress={() => toggleLogs('error')}
					icon={'❗️'}
				/>,
				<StaffKnob.Control
					key="ito-devmode-toggle"
					title={
						itoDevModeEnabled ? 'Disable Ito dev mode' : 'Enable Ito dev mode'
					}
					onPress={toggleItoDebugMode}
					icon={'🧐'}
				/>,
				pseudonymId && (
					<StaffKnob.Control
						key="mixpanel-link"
						title="Open user in Mixpanel"
						onPress={() => {
							const customPath =
								backendConfig.system === 'prod'
									? '2555120/view/3094809'
									: '2554920/view/3094812';
							const url = `https://eu.mixpanel.com/project/${customPath}/app/profile#distinct_id=${pseudonymId}`;
							Linking.openURL(url);
						}}
						icon={'🔬'}
					/>
				),
				isNative && (
					<StaffKnob.Control
						key="in-app-store-review"
						title={'Trigger in app store review'}
						onPress={() => navigation.navigate(ScreenKey.AppReview)}
						icon={'⭐️'}
					/>
				),
			],
			backendEnvs.map((backendEnv) => (
				<StaffKnob.Control
					key={`backend-env-${backendEnv.name}`}
					title={`Select ${backendEnv.system}/${backendEnv.name}${
						backendConfig.system === backendEnv.system &&
						backendConfig.env === backendEnv.name
							? ' 👈'
							: ''
					}`}
					icon={backendEnv.emoji}
					onPress={() => setBackendConfigEnv(backendEnv)}
				/>
			)),
			[
				<ScreenLinkProvider
					key="home"
					screen={ScreenKey.Home}
					options={closeOptions}
				>
					<StaffKnob.Control title="Home →" icon="🏠" />
				</ScreenLinkProvider>,
				<ScreenLinkProvider
					key="cms-preview"
					screen={ScreenKey.DevCMSContent}
					options={closeOptions}
				>
					<StaffKnob.Control title="CMSContent preview →" icon="📄" />
				</ScreenLinkProvider>,
				<ScreenLinkProvider
					key="change-poll-responses"
					screen={ScreenKey.ChangePollResponses}
					options={closeOptions}
				>
					<StaffKnob.Control title="Change poll responses →" icon="🏋️" />
				</ScreenLinkProvider>,
				<ScreenLinkProvider
					key="see-all-activities"
					screen={ScreenKey.SeeAllActivities}
					options={closeOptions}
				>
					<StaffKnob.Control title="See all activities →" icon="📝" />
				</ScreenLinkProvider>,
				<ScreenLinkProvider
					key="content-validation"
					screen={ScreenKey.ContentValidation}
					options={closeOptions}
				>
					<StaffKnob.Control title="Content validation →" icon="☑️" />
				</ScreenLinkProvider>,
				<StaffKnob.Control
					key="log-out"
					title="Log out"
					icon="🚪"
					onPress={onLogout}
				/>,
				<View key="backend-config-tag" style={styles.textInput}>
					<TextInput
						label="Backend config - tag"
						value={backendConfig.tag}
						onChangeText={(tag) => setBackendConfigTag(tag.trim() || undefined)}
						autoCapitalize="none"
						autoComplete="off"
					/>
				</View>,
				<View key="impersonated-user-id" style={styles.textInput}>
					<TextInput
						label="Impersonated user ID"
						value={impersonatedUserId}
						onChangeText={(id) =>
							dispatch(setImpersonatedUserId(id.trim() || undefined))
						}
						autoCapitalize="none"
						autoComplete="off"
					/>
				</View>,
			],
		];
	}, [
		onPressReload,
		appInstanceKey,
		userId,
		notificationStatus,
		sanityDataset,
		closeOptions,
		backendConfig.system,
		backendConfig.tag,
		backendConfig.env,
		onCreateTestUser,
		hasSubscription,
		onCreateTestSubscription,
		logsEnabled.analytics,
		logsEnabled.warning,
		logsEnabled.error,
		itoDevModeEnabled,
		toggleItoDebugMode,
		pseudonymId,
		isNative,
		backendEnvs,
		onLogout,
		styles.textInput,
		impersonatedUserId,
		dispatch,
		resetUserActivity,
		testIncrementTrackingDate,
		timezone,
		testCompleteActivityDays,
		notificationRequest,
		changeSanityProject,
		toggleLogs,
		navigation,
		setBackendConfigEnv,
		setBackendConfigTag,
	]);

	return (
		<StaffKnob
			controls={controls}
			isExpanded={isExpanded}
			setIsExpanded={setIsExpanded}
			showItoDevModeToggle={!!isItoOn}
			onItoDevModeToggle={toggleItoDebugMode}
		>
			<Stack spacing={Spacing.extraSmall}>
				<Text themeColor="content_on_fill">
					<Text weight="bold">backend system:</Text> {backendConfig.system}
				</Text>
				<Text themeColor="content_on_fill">
					<Text weight="bold">backend env:</Text> {backendConfig.env}
				</Text>
				<Text themeColor="content_on_fill">
					<Text weight="bold">backend tag:</Text> {backendConfig.tag}
				</Text>
				<Text selectable themeColor="content_on_fill">
					<Text weight="bold">userId:</Text> {userId}
				</Text>
				<Text selectable themeColor="content_on_fill">
					<Text weight="bold">sessionId:</Text> {sessionId}
				</Text>
				{isIos && (
					<Text selectable themeColor="content_on_fill">
						<Text weight="bold">rcUserId:</Text> {rcUserId}
					</Text>
				)}
				<Text selectable themeColor="content_on_fill">
					<Text weight="bold">device vendorId:</Text>{' '}
					{clientContext?.device.vendorId ?? ''}
				</Text>
				<Text themeColor="content_on_fill">
					<Text weight="bold">sanity project: </Text> {sanityDataset}
				</Text>
				{!!trackingDate && (
					<Text themeColor="content_on_fill">
						<Text weight="bold">tracking date: </Text>{' '}
						{format(new Date(trackingDate.toString()), 'yyyy-MM-dd')}
					</Text>
				)}
			</Stack>
		</StaffKnob>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	textInput: {
		marginTop: spacing.xs,
	},
}));
